import React from "react";
import "./css/pages.css";
import { Container, Fade, Col, Row } from "reactstrap";
import VitaPage from "./VitaPage";
import news1 from "./pics/news/Ausstellung_Einladung.jpg";
import news3 from "./pics/news/Einladung_Vernissage_2019-page-001.jpg";
import news4 from "./pics/news/Flyer.PNG";

class NewsPage extends VitaPage {
  componentDidMount() {
    this.toggle();
  }
  render() {
    const myNewsEntries = newsEntries.map((entry, index) => (
      <NewsEntry key={entry.id} {...entry} />
    ));

    return (
      <Container>
        <Row>
          <Col lg="12">
            <h1 className="text-right">news&nbsp;&amp;&nbsp;events</h1>
          </Col>
        </Row>
        <Fade in={this.state.fadeIn}>{myNewsEntries}</Fade>
      </Container>
    );
  }
}
export default NewsPage;

class NewsEntry extends React.Component {
  render() {
    return (
      <Row className="d-flex news-entry">
        <Col className="text-right align-self-end">
          <h3 className="mt-5">
            {this.props.title}
            <small> - {this.props.subTitle}</small>
          </h3>
          <div>
            <p>{this.props.text}</p>
            <p>
              {" "}
              {this.props.location} {this.props.location ? " - " : ""}{" "}
              {this.props.eventDate}
            </p>
            <p>
              Posted by : Zoltan T. Weber,
              <br />
              Date: {this.props.dateCreated}
            </p>
            {this.props.link ? (
              <a href={this.props.link}>*** Download *** </a>
            ) : (
              ""
            )}
          </div>
        </Col>
        <Col xs="12" sm="12" md="6" lg="4">
          {this.props.picture ? (
            <img
              src={this.props.picture}
              alt={this.props.title}
              className="img-fluid"
            />
          ) : (
            ""
          )}
        </Col>
      </Row>
    );
  }
}

const newsEntries = [
  {
    id: 4,
    title: "50 Jahre die MontagsMaler in Hamburg",
    subTitle: "Collective Exhibition",
    text: "Vernissage 10.01.2020 5pm",
    location: "GALERIEKA, Ludolfstrasse 60, 20249 Hamburg",
    dateCreated: "25.VIII.2019",
    eventDate: "10.01.-02.02.2020",
    picture: news4,
    link: ""
  },
  {
    id: 3,
    title: "Spurensicherung",
    subTitle: "Collective Exhibition",
    text: "Vernissage 18.10.2019 6:30pm",
    location: "VOR-ORT-BÜRO, Zimmerpforte 8, 20099 Hamburg",
    dateCreated: "25.VIII.2019",
    eventDate: "14.10 – 19.10.2019",
    picture: news3,
    link: ""
  },
  {
    id: 2,
    title: "3rd Prize Artcompetition 2019",
    subTitle: "Journal from St. Georg",
    text:
      "Oktober 2019. Die Zeitung des Bürgervereins zu St. Georg von 1880 e.V. 6. p.",
    location: "",
    dateCreated: "06.I.2020",
    eventDate: "X/2019",
    picture: "",
    link:
      "https://buergerverein-stgeorg.de/wp-content/uploads/bsk-pdf-manager/2019/10/Oktober_19_Internet.pdf"
  },
  {
    id: 1,
    title: "Durch den Garten",
    subTitle: "Exhibition",
    text: "",
    location: "Cafe Gnosa, Lange Reihe 93, 20099 Hamburg",
    dateCreated: "25.VIII.2019",
    eventDate: "IX-XII/2019",
    picture: news1,
    link: ""
  }
];
