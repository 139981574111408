import React from "react";
import { Row, Col } from "reactstrap";
import pageNotFoundPicture from "./pics/404.jpg";

export function pageNotFound({ location }) {
  return (
    <Row>
      <Col lg="12">
        <div className="my-5">
          <h3 className="my-5">
            The URL you are looking for " <code>{location.pathname}</code> " is
            not a valid page of this web application.
          </h3>
          <img
            className="err-image"
            src={pageNotFoundPicture}
            alt="eCorrect 404 Page Not Found"
          />
          <h3 className="my-5">
            Please refer to a valid URL address or contact your administrator.
          </h3>
        </div>
      </Col>
    </Row>
  );
}
