import React from "react";
import "./css/pages.css";
import { Container, Row, Fade } from "reactstrap";
import VitaPage from "./VitaPage";
import image from "./pics/home_xl.jpg";

class HomePage extends VitaPage {
  componentDidMount() {
    this.toggle();
  }
  render() {
    return (
      <Fade
        in={this.state.fadeIn}
        className="default-page-size d-flex flex-column justify-content-center align-items-center"
      >
        <Row className="d-flex justify-content-end w-75">
          <h1 className="text-right mr-3 mb-4">
            life-affirming attitude and spirit
          </h1>
        </Row>
        <div className="w-75 d-flex flex-column justify-content-center">
          <Row className="d-flex justify-content-center">
            <div>
              <img src={image} alt="home" className="img-fluid" />
            </div>
          </Row>
        </div>
        <Container>
          <Row className="d-flex mt-3">
            <p className="home-text font-weight-light">
              Capturing a moment of mystery and secret with a distinct style –
              both realistic and abstract - are the main features of Zoltan T.
              Weber`s compositions. With a precise and focused observation,
              details – such as berries, branches, leaves or petals with an
              unusual perspective – relay emotions, power, harmony and
              melancholy at once. The interaction between Nature and the
              observed flowers/plants/garden detail, as well as objects and
              people, brings his paintings alive with an expressive use of
              acrylic and pastel, or oil on canvas.
              <br></br>
              The colorful, appetizing motives (pastries and cookies, red wine,
              fruit, etc.) and atmospheric lifestyle moments tell stories and
              encourage the viewer to explore his own introspection in a
              personal, subjective way, by sharing this life-affirming attitude
              and spirit.
            </p>
          </Row>
        </Container>
      </Fade>
    );
  }
}
export default HomePage;
