import React from "react";
import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  NavItem,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu
} from "reactstrap";
import "./css/navbarAndUserinfo.css";
import { NavLink } from "react-router-dom";
import headerLogo from "./pics/header-logo.jpg";
import withWindowDimensions from "../components/hoc/withWIndowDimensions";

class BasicNavbar extends React.Component {
  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: false,
      width: 800
    };
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }
  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }
  componentDidMount() {
    this.setState({ width: window.innerWidth });
    window.addEventListener("resize", this.updateDimensions);
  }

  render() {
    let roleRelatedLinks =
      this.props.authenticated === 1
        ? this.props.linkGroups[this.props.authority].map((link, index) => (
            <NavItem key={link.name + index.toString()}>
              <NavLink activeClassName={link.activeClassName} to={link.to}>
                {link.name}
              </NavLink>
            </NavItem>
          ))
        : "";

    return (
      <Container>
        <Row className="d-flex justify-content-center align-items-center my-3">
          <Col xs="12" sm="12" md="12" lg="3">
            <img
              src={headerLogo}
              alt="Zoltan T. Weber Gallery Art Online"
              className="img-fluid"
            />
          </Col>
          <Col xs="12" sm="12" md="12" lg="9" id="navbar-col">
            <Navbar>
              {this.state.width > 768 ? (
                <Nav>
                  {this.props.linkGroups.public.map((link, index) =>
                    link.isDropdownMenuItem ? (
                      this.state.collapsed ? (
                        ""
                      ) : (
                        <CustomDropdown
                          key={index}
                          links={link.dropdownLinks}
                          name={link.name}
                        />
                      )
                    ) : this.state.collapsed ? (
                      ""
                    ) : (
                      <NavItem key={link.name + index.toString()}>
                        <NavLink
                          activeClassName={link.activeClassName}
                          to={link.to}
                        >
                          {link.name.toLowerCase()}
                        </NavLink>
                      </NavItem>
                    )
                  )}
                  {roleRelatedLinks}
                </Nav>
              ) : (
                <Nav navbar>
                  {this.props.linkGroups.public.map((link, index) =>
                    link.isDropdownMenuItem ? (
                      this.state.collapsed ? (
                        ""
                      ) : (
                        <CustomDropdown
                          key={index}
                          links={link.dropdownLinks}
                          name={link.name}
                        />
                      )
                    ) : this.state.collapsed ? (
                      ""
                    ) : (
                      <NavItem key={link.name + index.toString()}>
                        <NavLink
                          activeClassName={link.activeClassName}
                          to={link.to}
                        >
                          {link.name.toLowerCase()}
                        </NavLink>
                      </NavItem>
                    )
                  )}
                  {roleRelatedLinks}
                </Nav>
              )}
            </Navbar>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withWindowDimensions(BasicNavbar);

class CustomDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false
    };
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  render() {
    let dropdownItems = this.props.links.map(link => (
      <DropdownItem key={link.id}>
        <NavItem>
          <NavLink activeClassName={link.activeClassName} to={link.to}>
            {link.name.toLowerCase()}
          </NavLink>
        </NavItem>
      </DropdownItem>
    ));
    return (
      <Dropdown nav isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <DropdownToggle nav caret>
          {this.props.name.toLowerCase()}
        </DropdownToggle>
        <DropdownMenu>{dropdownItems}</DropdownMenu>
      </Dropdown>
    );
  }
}
