import React from "react";
import "./css/pages.css";
import { Fade, Container, Row, Col } from "reactstrap";
import picture from "./pics/self-portrait.jpg";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";

class VitaPage extends React.Component {
  constructor() {
    super();
    this.state = {
      siteOwner: "Zoltan T. Weber",
      fadeIn: false
    };
    this.toggle = this.toggle.bind(this);
    this.getYear = this.getYear.bind(this);
  }
  toggle() {
    this.setState({
      fadeIn: !this.state.fadeIn
    });
  }
  renderScrollToTopButton() {
    return (
      <div>
        <ScrollUpButton />
      </div>
    );
  }
  getYear() {
    let year = new Date().getFullYear();
    return year;
  }
  getSiteOwner() {
    let siteOwner = this.state.siteOwner;
    return siteOwner;
  }

  componentDidMount() {
    this.toggle();
  }

  render() {
    const text = (
      <div>
        <p className="vita-text">
          Aesthete, flower-lover and hobby gardener. Seeking elegant and
          aesthetic detail, fine atmospheric life elements (Nature, flowers,
          plants, beautiful people in an active setting, the more “appetizing”
          moments of life, passionate dance) – the whole spectrum of life
          “tastes”. Hobby baker with Hungarian roots and life-affirming
          attitude. A two-in-one down-to-earth dreamer! An economics graduate
          (Ph.D. received in 2013) with work experience at an oil company – as
          well as a painter. Always focussed, well-organised – and reverberating
          with an energy-laden desire for perfection. As an artist, seeking the
          critical perspective of the observer – as well as his own
          intrinsically self-critical perspective. Currently under the tutelage
          of Alexander Raymond. Having lived in Hamburg for four years, keen to
          “colour” and transform perspectives, simplify forms and capture
          mystery. As a multi-lingual transnational intellectual – perceives
          space in Art as in life – borderless.
        </p>
        <p>
          <strong>ZOLTAN T. WEBER </strong>
        </p>
      </div>
    );

    return (
      <Fade in={this.state.fadeIn} className="default-page-size">
        <Container>
          <Row>
            <Col lg="12">
              <h1 className="text-right">vita</h1>
            </Col>
          </Row>
          <Row>
            <Col lg="5" className="d-flex justify-content-start">
              {text}
            </Col>
            <Col lg="2"> </Col>
            <Col lg="5">
              <img
                src={picture}
                alt="ZOLTAN T. WEBER (1982)"
                className="img-fluid"
                id="self-portrait"
              />
            </Col>
          </Row>
        </Container>
      </Fade>
    );
  }
}
export default VitaPage;
