import React from "react";
import BasicNavbar from "./pages/BasicNavbar";
import BasicSettings from "./server/BasicSettings";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
// BASIC PAGES
import HomePage from "./pages/HomePage";
import PaintingsAndInteriorPage from "./pages/PaintingsAndInteriorPage";
import CollectionsFloralPage from "./pages/CollectionsFloralPage";
import CollectionsObjectsPage from "./pages/CollectionsObjectsPage";
import CollectionsLifestylePage from "./pages/CollectionsLifestylePage";
import NewsPage from "./pages/NewsPage";
import VitaPage from "./pages/VitaPage";
import ContactPage from "./pages/ContactPage";
import Footer from "./pages/Footer";
import { pageNotFound } from "./pages/PageNotFound";
import "./App.css";

class MainApp extends React.Component {
  constructor() {
    super();
    this.state = {
      authenticated: 0,
      authority: "VISITOR",
      userSettings: {},
      client: {},
      partner: {},
      basicSettings: new BasicSettings(),
      appVersion: "",
      loading: true,
      appTitle: "Weber-Art"
    };
  }

  componentDidMount() {}

  render() {
    let routeGroups = this.getRouteGroups();
    let linkGroups = this.state.basicSettings.getLinkGroups();

    return (
      <Router>
        <BasicNavbar
          appVersion={this.state.appVersion}
          appTitle={this.state.appTitle}
          authenticated={this.state.authenticated}
          authority={this.state.authority}
          userSettings={this.state.userSettings}
          linkGroups={linkGroups}
        />
        <Switch>
          {routeGroups.map(route => (
            <Route
              className="container-fluid"
              key={route.id}
              path={route.path}
              exact={route.exact}
              strict={route.strict}
              component={route.component}
            />
          ))}
          <Redirect exact from="/" to="/home" />
          <Route component={pageNotFound} />
        </Switch>

        <Footer />
      </Router>
    );
  }
  getRouteGroups() {
    let routeGroups = [
      {
        id: 1,
        exact: true,
        strict: true,
        onChange: "",
        path: "/home",
        component: () => <HomePage />
      },
      {
        id: 21,
        exact: true,
        strict: true,
        onChange: "",
        path: "/collections/floral",
        component: () => <CollectionsFloralPage />
      },
      {
        id: 22,
        exact: true,
        strict: true,
        onChange: "",
        path: "/collections/objects",
        component: () => <CollectionsObjectsPage />
      },
      {
        id: 23,
        exact: true,
        strict: true,
        onChange: "",
        path: "/collections/lifestyle",
        component: () => <CollectionsLifestylePage />
      },
      {
        id: 3,
        exact: true,
        strict: true,
        onChange: "",
        path: "/paintings-and-interior",
        component: () => <PaintingsAndInteriorPage />
      },
      {
        id: 4,
        exact: true,
        strict: true,
        onChange: "",
        path: "/news-and-events",
        component: () => <NewsPage />
      },
      {
        id: 5,
        exact: true,
        strict: true,
        onChange: "",
        path: "/vita",
        component: () => <VitaPage />
      },
      {
        id: 6,
        exact: true,
        strict: true,
        onChange: "",
        path: "/contact",
        component: () => <ContactPage />
      }
    ];

    return routeGroups;
  }
}

export default MainApp;
