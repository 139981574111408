import React from "react";
import "./css/pages.css";
import { Fade, Container, Row, Col } from "reactstrap";
import VitaPage from "./VitaPage";

class ContactPage extends VitaPage {
  componentDidMount() {
    this.toggle();
  }
  render() {
    const text = (
      <div>
        <p className="contact-text">
          Zoltan T. Weber
          <br />
          E-Mail : zoltan.t.weber[at]gmail[dot]com <br /> <br />
          Tel: +4915205240962 (after 5 p.m.)
          <br />
          Klaus-Groth-Strasse 34, <br />
          20535 Hamburg, <br />
          GERMANY
        </p>
        <p className="contact-text">
          &copy;&nbsp;{this.getYear()}&nbsp;All Artworks by : Zoltan T. Weber
          <br />
          Web Design : Aniko Olah L.
          <br />
          Fotos : Photosyang
          <br />
          Portrait : Mirko
          <br />
          Text : M. Lyndon
        </p>
      </div>
    );
    const legalText = (
      <p className="privacy-policy-text">
        <span>Limitation of liability for internal content</span> <br />
        The content of our website has been compiled with meticulous care and to
        the best of our knowledge. However, we cannot assume any liability for
        the up-to-dateness, completeness or accuracy of any of the pages.
        Pursuant to section 7, para. 1 of the TMG (Telemediengesetz – Tele Media
        Act by German law), we as service providers are liable for our own
        content on these pages in accordance with general laws. However,
        pursuant to sections 8 to 10 of the TMG, we as service providers are not
        under obligation to monitor external information provided or stored on
        our website. Once we have become aware of a specific infringement of the
        law, we will immediately remove the content in question. Any liability
        concerning this matter can only be assumed from the point in time at
        which the infringement becomes known to us. <br />
        <span>Limitation of liability for external links </span> <br />
        Our website contains links to the websites of third parties („external
        links“). As the content of these websites is not under our control, we
        cannot assume any liability for such external content. In all cases, the
        provider of information of the linked websites is liable for the content
        and accuracy of the information provided. At the point in time when the
        links were placed, no infringements of the law were recognisable to us.
        As soon as an infringement of the law becomes known to us, we will
        immediately remove the link in question.
        <br /> <span>Copyright </span> <br />
        The content and works published on this website are governed by the
        copyright laws of Germany. Any duplication, processing, distribution or
        any form of utilisation beyond the scope of copyright law shall require
        the prior written consent of the author in question. <br />{" "}
        <span>Data protection</span>
        <br /> A visit to our website can result in the storage on our server of
        information about the access (date, time, page accessed). This does not
        represent any analysis of personal data (e.g., name, address or e-mail
        address). If personal data are collected, this only occurs – to the
        extent possible – with the prior consent of the user of the website. Any
        forwarding of the data to third parties without the express consent of
        the user shall not take place. We would like to expressly point out that
        the transmission of data via the Internet (e.g., by e-mail) can offer
        security vulnerabilities. It is therefore impossible to safeguard the
        data completely against access by third parties. We cannot assume any
        liability for damages arising as a result of such security
        vulnerabilities. The use by third parties of all published contact
        details for the purpose of advertising is expressly excluded. We reserve
        the right to take legal steps in the case of the unsolicited sending of
        advertising information; e.g., by means of spam mail.
      </p>
    );
    return (
      <Fade in={this.state.fadeIn} className="default-page-size">
        <Container>
          <Row>
            <Col lg="12">
              <h1 className="text-right">
                contact<br></br>
                <small>
                  &nbsp;privacy policy&nbsp;&amp;&nbsp;data protection
                </small>
              </h1>
            </Col>
          </Row>
          <Row>
            <Col lg="5">{text}</Col>
            <Col lg="7">{legalText}</Col>
          </Row>
        </Container>
      </Fade>
    );
  }
}
export default ContactPage;
