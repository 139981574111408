import React from "react";
import "./css/pages.css";
import { Container, Row, Fade, Col } from "reactstrap";
import VitaPage from "./VitaPage";
import LifestyleGallery from "./collections/LifestyleGallery";

class CollectionsLifestylePage extends VitaPage {
  componentDidMount() {
    this.toggle();
  }
  render() {
    return (
      <Fade in={this.state.fadeIn} className="default-page-size">
        <Container>
          <Row>
            <Col lg="12">
              <h1 className="text-right">lifestyle</h1>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <LifestyleGallery />
            </Col>
            {this.renderScrollToTopButton()}
          </Row>
        </Container>
      </Fade>
    );
  }
}

export default CollectionsLifestylePage;
