import picture_1 from "../pics/paintings_and_interior/1.jpg";
import picture_2 from "../pics/paintings_and_interior/2.jpg";
import picture_3 from "../pics/paintings_and_interior/3.jpg";
import picture_4 from "../pics/paintings_and_interior/4.jpg";
import picture_5 from "../pics/paintings_and_interior/5.jpg";

export const photos = [
  {
    id: 1,
    src: picture_1,
    width: 7.2,
    height: 4.8,
    title:
      "Autumn in blue - motives capturing Nature and reflecting textures of wood in the form of branches are delightful and harmonizing eye-catchers in any classical interior. If you don’t have access to a garden or balcony, you can bring the greenery into your home instead, via lively, stimulating and subtle details. Dream-like colour combinations, with blue accents, suffuse the space a calm, contented, peaceful, relaxed personal atmosphere."
  },
  {
    id: 2,
    src: picture_2,
    width: 6.1,
    height: 4.1,
    title:
      "Cornus - a painting is a finishing touch in an interior. It should pick up on the basic colors in the room and, on the other hand, also bring some individuality to the space. The artwork should be awarded pride of place in the interior. This “window to nature”, with a red and blue-grey winter layout, creates a dynamic and expressive impression. Let your personality shine through, even if it’s not the conventional choice – that’s what gives your space individuality! "
  },
  {
    id: 3,
    src: picture_3,
    width: 7.2,
    height: 4.8,
    title:
      "Snowberries - collaborating colors such as white, grey and brown give peace to the interior. The elegance of the color white appears on the painting – incorporated in the view of white snowberries - fostering some romantic idyll upon the beauty of Nature, and offers comfort, sanctuary and a sense of belonging."
  },
  {
    id: 4,
    src: picture_4,
    width: 7.6,
    height: 5.1,
    title:
      "Strawberry-landscape : engaging visual stimulation with matching colours in the interior is always welcome. Some appetising lifestyle motives (a glass of wine, some fruit or cake) weave several personal tastes and interests into the design of your room, ensuring a curated, well-rounded atmosphere in the space.  It helps people to forget the outside world and encourages them to be present in the moment."
  },
  {
    id: 5,
    src: picture_5,
    width: 7.2,
    height: 4.7,
    title:
      "Midwinter fire - The first and the most important element in the interior is color. Cognac and warm brown shades make a strong, coherent statement about your moodboard. Shining red twigs, like a winter flame on a foggy morning, convey a sense of drama to the space."
  }
];
