import picture_37 from "../pics/lifestyle/37.jpg";
import picture_38 from "../pics/lifestyle/38.jpg";
import picture_39 from "../pics/lifestyle/39.jpg";
import picture_40 from "../pics/lifestyle/40.jpg";
import picture_41 from "../pics/lifestyle/41.jpg";
import picture_42 from "../pics/lifestyle/42.jpg";
import picture_43 from "../pics/lifestyle/43.jpg";
import picture_44 from "../pics/lifestyle/44.jpg";
import picture_45 from "../pics/lifestyle/45.jpg";
import picture_46 from "../pics/lifestyle/46.jpg";
import picture_47 from "../pics/lifestyle/47.jpg";
import picture_48 from "../pics/lifestyle/48.jpg";

export const photos = [
  {
    id: 37,
    src: picture_37,
    width: 5,
    height: 6.5,
    title: "The green Chair, Pastel, 65x50, 2018"
  },
  {
    id: 38,
    src: picture_38,
    width: 5,
    height: 6.5,
    title: "South Layout, Pastel, 65x50, 2018"
  },
  {
    id: 39,
    src: picture_39,
    width: 6.5,
    height: 5,
    title: "Festa, Pastel, 50x65, 2019"
  },
  {
    id: 40,
    src: picture_40,
    width: 5,
    height: 6.5,
    title: "Brainstorming, Acrylic/Pastel, 65x50, 2019"
  },
  {
    id: 41,
    src: picture_41,
    width: 6.5,
    height: 5,
    title: "Lamps and Cards, Pastel, 65x50, 2019"
  },
  {
    id: 42,
    src: picture_42,
    width: 5,
    height: 6.5,
    title: "At Working, Pastel, 65x50, 2019"
  },
  {
    id: 43,
    src: picture_43,
    width: 5,
    height: 6.5,
    title: "At Writing, Pastel, 65x50, 2019"
  },
  {
    id: 44,
    src: picture_44,
    width: 4,
    height: 6,
    title: "Etagere	Pastel, 65x50, 2019"
  },
  {
    id: 45,
    src: picture_45,
    width: 5,
    height: 6.5,
    title: "Puddle, Pastel, 65x50, 2019"
  },
  {
    id: 46,
    src: picture_46,
    width: 5,
    height: 6.5,
    title: "In and Out (Self Portrait), Pastel, 65x50, 2019"
  },
  {
    id: 47,
    src: picture_47,
    width: 5,
    height: 6.5,
    title: "Pepsi, Pastel, 65x50, 2019"
  },
  {
    id: 48,
    src: picture_48,
    width: 5,
    height: 7,
    title: "Year-Compass, Acrylic, 70x50, 2019"
  }
];
