import React from "react";
import "./css/pages.css";
import { Container, Row, Fade, Col } from "reactstrap";
import VitaPage from "./VitaPage";
import PaintingsAndInteriorGallery from "./collections/PaintingsAndInteriorGallery";

class CollectionsPaintingsAndInteriorPage extends VitaPage {
  componentDidMount() {
    this.toggle();
  }
  render() {
    return (
      <Fade in={this.state.fadeIn} className="default-page-size">
        <Container id="paintings-and-interior">
          <Row>
            <Col lg="12">
              <h1 className="text-right">paintings&nbsp;&amp;&nbsp;interior</h1>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <PaintingsAndInteriorGallery />
            </Col>
            {this.renderScrollToTopButton()}
            {this.renderScrollToTopButton()}
          </Row>
        </Container>
      </Fade>
    );
  }
}

export default CollectionsPaintingsAndInteriorPage;
