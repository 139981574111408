export default class BasicSettings {
  _appTitle = "Weber-Art";
  _appLinkGroups = {
    public: [
      {
        to: "/home",
        name: "Home",
        activeClassName: "selected",
        isDropdownMenuItem: false
      },
      {
        to: "/collections",
        name: "Collections",
        activeClassName: "selected",
        isDropdownMenuItem: true,
        dropdownLinks: [
          {
            id: 100,
            to: "/collections/floral",
            name: "Floral",
            activeClassName: "selected"
          },
          {
            id: 101,
            to: "/collections/lifestyle",
            name: "Lifestyle",
            activeClassName: "selected"
          },
          {
            id: 102,
            to: "/collections/objects",
            name: "Objects",
            activeClassName: "selected"
          }
        ]
      },
      {
        to: "/paintings-and-interior",
        name: "Paintings&Interior",
        activeClassName: "selected",
        isDropdownMenuItem: false
      },

      {
        to: "/news-and-events",
        name: "News&Events",
        activeClassName: "selected",
        isDropdownMenuItem: false
      },
      {
        to: "/vita",
        name: "Vita",
        activeClassName: "selected",
        isDropdownMenuItem: false
      },
      {
        to: "/contact",
        name: "Contact",
        activeClassName: "selected",
        isDropdownMenuItem: false
      }
    ]
  };

  constructor() {
    return this;
  }
  getAppTitle() {
    return this._appTitle;
  }
  setAppTitle(value) {
    this._appTitle = value;
  }
  getLinkGroups() {
    return this._appLinkGroups;
  }
}
