import picture_23 from "../pics/objects/23.jpg";
import picture_24 from "../pics/objects/24.jpg";
import picture_25 from "../pics/objects/25.jpg";
import picture_26 from "../pics/objects/26.jpg";
import picture_27 from "../pics/objects/27.jpg";
import picture_28 from "../pics/objects/28.jpg";
import picture_29 from "../pics/objects/29.jpg";
import picture_30 from "../pics/objects/30.jpg";
import picture_31 from "../pics/objects/31.jpg";
import picture_32 from "../pics/objects/32.jpg";
import picture_33 from "../pics/objects/33.jpg";
import picture_34 from "../pics/objects/34.jpg";
import picture_35 from "../pics/objects/35.jpg";
import picture_36 from "../pics/objects/36.jpg";
import picture_49 from "../pics/objects/49.jpg";
import picture_50 from "../pics/objects/50.jpg";
import picture_51 from "../pics/objects/51.jpg";

export const photos = [
  {
    id: 23,
    src: picture_23,
    width: 5,
    height: 6.5,
    title: "Cornus and Sweetpotato, Pastel, 65x50, 2018"
  },
  {
    id: 24,
    src: picture_24,
    width: 5,
    height: 6.5,
    title: "Slide, Pastel, 65x50, 2019"
  },
  {
    id: 25,
    src: picture_25,
    width: 5,
    height: 6.5,
    title: "Strawberry Landscape, Pastel, 65x50, 2019"
  },
  {
    id: 26,
    src: picture_26,
    width: 5,
    height: 6.5,
    title: "Reading Materials, Pastel, 65x50, 2018"
  },
  {
    id: 27,
    src: picture_27,
    width: 3.7,
    height: 7,
    title: "Harvest, Acrylic/Pastel, 70x37, 2018"
  },
  {
    id: 28,
    src: picture_28,
    width: 5,
    height: 6.5,
    title: "Sommer-Pattern, Pastel, 65x50, 2019"
  },
  {
    id: 29,
    src: picture_29,
    width: 5,
    height: 6.5,
    title: "Bowl full of Expressions, Pastel, 65x50, 2018"
  },
  {
    id: 30,
    src: picture_30,
    width: 5,
    height: 6.5,
    title: "Hedonismus, Pastel, 65x50, 2018"
  },
  {
    id: 31,
    src: picture_31,
    width: 8,
    height: 8,
    title: "The brown Hut, Oil on Canvas, 80x80, 2019"
  },
  {
    id: 32,
    src: picture_32,
    width: 5,
    height: 6.5,
    title: "Autumn Basket, Pastel, 65x50, 2018"
  },
  {
    id: 33,
    src: picture_33,
    width: 5,
    height: 7,
    title: "Onions, Acrylic/Pastel, 70x50, 2018"
  },
  {
    id: 34,
    src: picture_34,
    width: 7,
    height: 5,
    title: "Burger-time, Acrylic/Pastel, 70x50, 2018"
  },
  {
    id: 35,
    src: picture_35,
    width: 5,
    height: 6.5,
    title: "Teddy, Pastel, 65x50, 2019"
  },
  {
    id: 36,
    src: picture_36,
    width: 5,
    height: 6.5,
    title: "Kallas, Pastel, 65x50, 2018"
  },
  {
    id: 49,
    src: picture_49,
    width: 4,
    height: 5,
    title: "Green Pots, Acrylic, 50x40, 2020"
  },
  {
    id: 50,
    src: picture_50,
    width: 5,
    height: 6.5,
    title: "Lantern, Pastel, 65x50, 2019"
  },
  {
    id: 51,
    src: picture_51,
    width: 5,
    height: 6.5,
    title: "Striped, Pastel, 65x50, 2019"
  }
];
