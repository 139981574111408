import picture_1 from "../pics/floral/1.jpg";
import picture_2 from "../pics/floral/2.jpg";
import picture_3 from "../pics/floral/3.jpg";
import picture_4 from "../pics/floral/4.jpg";
import picture_5 from "../pics/floral/5.jpg";
import picture_6 from "../pics/floral/6.jpg";
import picture_7 from "../pics/floral/7.jpg";
import picture_8 from "../pics/floral/8.jpg";
import picture_9 from "../pics/floral/9.jpg";
import picture_10 from "../pics/floral/10.jpg";
import picture_11 from "../pics/floral/11.jpg";
import picture_12 from "../pics/floral/12.jpg";
import picture_13 from "../pics/floral/13.jpg";
import picture_14 from "../pics/floral/14.jpg";
import picture_15 from "../pics/floral/15.jpg";
import picture_16 from "../pics/floral/16.jpg";
import picture_17 from "../pics/floral/17.jpg";
import picture_18 from "../pics/floral/18.jpg";
import picture_19 from "../pics/floral/19.jpg";
import picture_20 from "../pics/floral/20.jpg";
import picture_21 from "../pics/floral/21.jpg";
import picture_22 from "../pics/floral/22.jpg";

export const photos = [
  {
    id: 1,
    src: picture_1,
    width: 3,
    height: 7,
    title: "Snowdrops, Acrylic/Pastel, 70x30, 2019"
  },
  {
    id: 2,
    src: picture_2,
    width: 7,
    height: 9.3,
    title: "Cornus, Acrylic/Pastel, 93x70, 2019"
  },
  {
    id: 3,
    src: picture_3,
    width: 7,
    height: 10,
    title: "The blue Autumn, Acrylic/Pastel, 100x70, 2019"
  },
  {
    id: 4,
    src: picture_4,
    width: 1,
    height: 1,
    title: "Midwinter Fire, Acrylic/Pastel, 70x70, 2019"
  },
  {
    id: 5,
    src: picture_5,
    width: 7,
    height: 10,
    title: "Snowberry, Acrylic/Pastel, 100x70, 2019"
  },
  {
    id: 6,
    src: picture_6,
    width: 1,
    height: 1,
    title: "Wild Grape, Acrylic/Pastel, 70x70, 2019"
  },
  {
    id: 7,
    src: picture_7,
    width: 5,
    height: 7,
    title: "Queen of the Night, Acrylic/Pastel, 70x50, 2019"
  },
  {
    id: 8,
    src: picture_8,
    width: 7,
    height: 7,
    title: "Tulips in the Rain, Acrylic/Pastel, 70x70, 2019"
  },
  {
    id: 9,
    src: picture_9,
    width: 3,
    height: 7,
    title: "White Tulips, Acrylic/Pastel, 70x30, 2019"
  },
  {
    id: 10,
    src: picture_10,
    width: 7,
    height: 10,
    title: "Fritillaria, Acrylic/Pastel, 100x70, 2019"
  },
  {
    id: 11,
    src: picture_11,
    width: 3,
    height: 6,
    title: "Snowberries (small), Acrylic/Pastel, 63x30, 2019"
  },
  {
    id: 12,
    src: picture_12,
    width: 1,
    height: 1,
    title: "Nut, Acrylic/Pastel, 70x70, 2019"
  },
  {
    id: 13,
    src: picture_13,
    width: 7,
    height: 8.5,
    title: "Love Pearls, Acrylic/Pastel, 85x70, 2019"
  },
  {
    id: 14,
    src: picture_14,
    width: 1,
    height: 1,
    title: "Cherry Blossom, Acrylic/Pastel, 70x70, 2019"
  },
  {
    id: 15,
    src: picture_15,
    width: 5,
    height: 7,
    title: "Pulsatilla Rubra, Acrylic/Pastel, 70x50, 2019"
  },
  {
    id: 16,
    src: picture_16,
    width: 7,
    height: 7,
    title: "Red in Winter, Acrylic/Pastel, 70x70, 2019"
  },
  {
    id: 17,
    src: picture_17,
    width: 5,
    height: 7,
    title: "Lights in Autumn, Acrylic/Pastel, 70x50, 2019"
  },
  {
    id: 18,
    src: picture_18,
    width: 3,
    height: 7,
    title: "Love Pearls (small), Acrylic/Pastel, 70x30, 2019"
  },
  {
    id: 19,
    src: picture_19,
    width: 5,
    height: 6.5,
    title: "Rose Hips, Acrylic/Pastel, 65x50, 2018"
  },
  {
    id: 20,
    src: picture_20,
    width: 3,
    height: 7,
    title: "Lights in Autumn (small), Acrylic/Pastel, 70X30, 2019"
  },
  {
    id: 21,
    src: picture_21,
    width: 5,
    height: 7,
    title: "Pulsatilla Rubra, Acrylic/Pastel, 70x50, 2019"
  },
  {
    id: 22,
    src: picture_22,
    width: 7,
    height: 7,
    title: "Winter Layout, Acrylic/Pastel, 70x70, 2019"
  }
];
