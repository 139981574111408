import React from "react";
import "./css/pages.css";
import { Container, Row, Col } from "reactstrap";
//import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VitaPage from "./VitaPage";

class Footer extends VitaPage {
  render() {
    return (
      <Container className="footer-body">
        <Row className="d-flex justify-content-center">
          <Col
            xs="12"
            sm="12"
            md="12"
            lg="6"
            className="d-flex justify-content-center"
          >
            &copy;&nbsp;{this.getYear()} {this.getSiteOwner()}{" "}
            <a href="https://www.facebook.com/art.weber.5437">
              <FontAwesomeIcon icon={["fab", "facebook-f"]} size="2x" />
            </a>
          </Col>
        </Row>
      </Container>
    );
  }
}
export default Footer;
